import Head from "next/head";
import { Session } from "next-auth";
import { useState, useEffect } from "react";

import NavBar from "../NavBar";
import styles from "./Layout.module.css";

type LayoutProps = {
  session: Session;
  children?: React.ReactNode;
};

const contentStyle: React.CSSProperties = {
  minHeight: "85vh",
};

export default function Layout({ session, children }: LayoutProps) {
  const [isDev, setIsDev] = useState(false);

  useEffect(() => {
    const host = window.location.host;
    setIsDev(host.includes("dev.tda.link"));
  });

  if (!session) {
    return children;
  }

  return (
    <>
      <Head>
        <title>Launchpad</title>
      </Head>

      {session && isDev && (
        <div className="alert alert-info w-100 mb-0 text-center pt-1 pb-1 text-uppercase border-0 rounded-0">
          Development Environment
        </div>
      )}

      <NavBar session={session} />

      <main style={contentStyle}>{children}</main>
      <footer className={styles.footer}>
        <img src="/txdp.png" />
        Powered by TX Data Platform
      </footer>
    </>
  );
}
